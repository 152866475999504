import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import gsap from 'gsap/gsap-core'

import { StaticImage } from 'gatsby-plugin-image'

import colors from '@styles/colors'
import media from '@styles/media'
import text from '@styles/text'

import useMedia from '@hooks/useMedia'

const Hero: React.FC = () => {

  const wrapperRef = useRef(null)
  const img1Ref = useRef(null)
  const img2Ref = useRef(null)

  useEffect(() => {  
    const imgZoom = gsap.timeline({
      scrollTrigger: {
        trigger: wrapperRef.current,
        scrub: true,
        start: "top top"
      }
    })
    imgZoom.to(img1Ref.current, {
      scale: "1.1"
    }, 0)
    imgZoom.to(img2Ref.current, {
      scale: "1.1"
    }, 0)

    return () => {
      imgZoom.kill()
    }
    
  }, [img1Ref, img2Ref, wrapperRef])

  return (
    <Wrapper ref={wrapperRef} id="about-hero">
      <Top>
        <Background>
          <StaticImage
            src={'../../images/png/accentBG.png'} 
            alt="gradient"
            objectFit="cover"
            placeholder="blurred"
            loading="eager"
            style={{
              width: '100%', 
              height: '100%'
            }}
          />
        </Background>
        <Title>{useMedia("Your partners in\ninnovation", "Your partners in\ninnovation", "Your partners\nin innovation", "Your partners in\ninnovation")}</Title>
        <Img1Wrapper>
          <Img ref={img1Ref}>
            <StaticImage
              src={'../../images/jpg/aboutImg1.jpg'} 
              alt="pipes"
              objectFit="cover"
              placeholder="blurred"
              loading="eager"
              style={{width: '100%', height: '100%'}}
            />
          </Img>
        </Img1Wrapper>
        <Img2Wrapper>
          <Img ref={img2Ref}>
            <StaticImage
              src={'../../images/jpg/aboutImg2.jpg'} 
              alt="office space"
              objectFit="cover"
              placeholder="blurred"
              loading="eager"
              style={{width: '100%', height: '100%'}}
            />
          </Img>
        </Img2Wrapper>
      </Top>
    </Wrapper>
  )
}

export default Hero

const Wrapper = styled.section`
  background-color: ${colors.white};
  min-height: 100vh;
  height: 66.667vw;

  ${media.tablet} {
    min-height: unset;
    height: 113.772vw;
  }

  ${media.mobile} {
    min-height: unset;
    height: 250vw;
  }
`

const Top = styled.div`
  background-color: ${colors.black};
  position: relative;
  box-sizing: border-box;
  
  padding-top: 11.806vw;
  padding-left: 11.319vw;
  padding-bottom: 23.472vw;
  height: 50.722vw;

  ${media.tablet} {
    padding-top: 27.545vw;
    padding-left: 5.988vw;
    padding-bottom: 48.383vw;
    height: 88.623vw;
  }
  
  ${media.mobile} {
    padding-top: 42.133vw;
    padding-left: 6.667vw;
    padding-bottom: 141.333vw;
    height: 197.333vw;
  }
`

const Title = styled.h1`
  display: flex;
  flex-direction: column;
  color: ${colors.white};
  margin: 0px;
  white-space: pre-wrap;

  ${media.fullWidth} {
    ${text.desktopLHeading}
    width: 39.861vw;
  }

  ${media.desktop} {
    ${text.desktopLHeading}
    width: 39.861vw;
  }

  ${media.tablet} {
    ${text.tabletLHeading}
    width: 50vw;
  }

  ${media.mobile} {
    ${text.mobileXSHeading}
    width: 86.667vw;
  }
`

const Img1Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;

  ${media.fullWidth} {
    width: 45.417vw;
    height: 37.986vw;
    right: 3.403vw;
    top: 15.833vw;
  }

  ${media.desktop} {
    width: 45.417vw;
    height: 37.986vw;
    right: 3.403vw;
    top: 15.833vw;
  }

  ${media.tablet} {
    width: 49.102vw;
    height: 59.042vw;
    right: 0vw;
    top: 55.449vw;
  }

  ${media.mobile} {
    width: 69.333vw;
    height: 94.667vw;
    right: 0vw;
    top: 77vw;
  }
`

const Img2Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;

  width: 35.486vw;
  height: 24.375vw;
  right: 40.139vw;
  top: 36.319vw;

  ${media.tablet} {
    width: 57.964vw;
    height: 42.036vw;
    right: 21.078vw;
    top: 84.431vw;
  }

  ${media.mobile} {
    width: 68.267vw;
    height: 71.467vw;
    right: 25.067vw;
    top: 150vw;
  }
`

const Img = styled.div`
  width: 100%;
  height: 100%;
`

const Background = styled.div`
  position: absolute;
  transform: rotate(32deg) scaleY(-1);

  width: 66.111vw;
  height: 66.111vw;
  bottom: -17vw;
  left: -4vw;

  ${media.tablet} {
    width: 100.838vw;
    height: 100.838vw;
    bottom: -27vw;
    left: -22vw;
  }

  ${media.mobile} {
    width: 160vw;
    height: 160vw;
    bottom: -2vw;
    left: -51vw;
  }
`
